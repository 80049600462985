import axios from 'axios';
import { GeometryObject } from 'geojson';
import APIUtils from './APIUtils';

interface GeoAddress {
  address?: string;
  latitude?: number;
  longitude?: number;
}

interface SearchQuery {
  text: string;
  geojson: GeometryObject;
}

interface SearchResult<T = any> {
  id: number;
  data: T;
  geojson: GeometryObject;
}

const api = {
  search: async <T = any>(query: Partial<SearchQuery>) => {
    const resp = await axios.put<SearchResult<T>[]>('search/search.php', {
      ...APIUtils.clean(query),
    });
    return resp.data;
  },

  address: {
    autocomplete: async (search: string) => {
      const resp = await axios.get<{ streets?: { street: string | string[] } }>(
        'search/address/autocomplete.php',
        {
          params: {
            search: search,
          },
        }
      );
      const street = resp.data?.streets?.street || [];
      return Array.isArray(street) ? street : [street];
    },

    geocode: async (address: string) => {
      const resp = await axios.get<{
        latt: number;
        longt: number;
        error: { code: number };
        standard: { confidence: number };
      }>('search/address/geocode.php', { params: { search: address } });
      return resp.data;
    },
  },
};

export default { api };
export { GeoAddress, SearchQuery, SearchResult };
