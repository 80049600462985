import axios from 'axios';
import APIUtils from './APIUtils';
import LocaleUtils, { LanguageObject } from './LocaleUtils';

interface LanguageCapability {
  id: number;
  key: string;
  domain: ['health_professional'];
  text: LanguageObject;
  language: string;
  visible: boolean;
  color?: string;
  level?: LanguageObject;
}

const languageCapability = {
  generateDefault: (
    item?: Partial<LanguageCapability>
  ): Partial<LanguageCapability> => {
    return {
      id: item?.id,
      key: item?.key,
      domain: item?.domain,
      text: LocaleUtils.langobj.generate(item?.text),
      language: item?.language || 'fr',
      visible: item?.visible !== undefined ? item?.visible : true,
      color: item?.color,
      level: LocaleUtils.langobj.generate(item?.level),
    };
  },

  domains: ['health_professional', 'point_of_service'],

  api: {
    list: async (params?: { domain: string }) => {
      const resp = await axios.get<LanguageCapability[]>(
        'languageCapability/list.php',
        { params: params }
      );
      return resp.data;
    },
    get: async (id: number | string) => {
      const resp = await axios.get<LanguageCapability>(
        'languageCapability/get.php',
        {
          params: { id: id },
        }
      );
      return resp.data;
    },
    create: async (item: Partial<LanguageCapability>) => {
      const resp = await axios.post<LanguageCapability>(
        'languageCapability/create.php',
        {
          ...APIUtils.clean(item),
        }
      );
      return resp.data;
    },
    update: async (item: Partial<LanguageCapability>) => {
      const resp = await axios.put<LanguageCapability>(
        'languageCapability/update.php',
        {
          ...APIUtils.clean(item),
        }
      );
      return resp.data;
    },
    remove: async (id: number | string) => {
      const resp = await axios.delete<LanguageCapability>(
        'languageCapability/remove.php',
        {
          params: { id: id },
        }
      );
      return resp.data;
    },
  },
};

export default { languageCapability };
export { LanguageCapability };
