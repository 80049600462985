

















import ProvinceUtils, { Province } from '@/utils/ProvinceUtils';
import { isArray } from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: [Object as () => Province, Array as () => Province[]],
    },
    label: {
      type: String as () => string,
    },
    errorMessages: {
      type: Array as () => string[],
    },
    loading: {
      type: Boolean as () => boolean,
    },
    multiple: {
      type: Boolean as () => boolean,
    },
  },

  computed: {
    internal: {
      get(): Province | Province[] | undefined {
        if (isArray(this.value)) {
          return this.items?.filter((item) =>
            this.compare(item, this.value as Province)
          );
        } else {
          return this.items?.find((item) =>
            this.compare(item, this.value as Province)
          );
        }
      },
      set(item: Province) {
        this.$emit('input', item);
      },
    },
  },

  data: (): {
    items?: Province[];
  } => ({
    items: undefined,
  }),

  async mounted() {
    try {
      this.items = await ProvinceUtils.province.api.list();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },

  methods: {
    compare(item: Province, value: Province) {
      return item.id === value?.id || item.key === value?.key;
    },
  },
});
