import { render, staticRenderFns } from "./PointOfServiceForm.vue?vue&type=template&id=5e072ea9&scoped=true&v-if=internal&"
import script from "./PointOfServiceForm.vue?vue&type=script&lang=ts&"
export * from "./PointOfServiceForm.vue?vue&type=script&lang=ts&"
import style0 from "./PointOfServiceForm.vue?vue&type=style&index=0&id=5e072ea9&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e072ea9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VContainer,VSelect,VSwitch,VTextField})
