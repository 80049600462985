































































































































































import PointOfServiceUtils, {
  PointOfService,
} from '@/utils/PointOfServiceUtils';
import ProviderUtils, { Provider } from '@/utils/ProviderUtils';
import SearchUtils from '@/utils/SearchUtils';
import Autocomplete from '@/components/search/Autocomplete.vue';
import LanguageDesignationPicker from '@/components/languageDesignation/LanguageDesignationPicker.vue';
import Vue from 'vue';
import { AutocompleteItem } from '../search/types';
import LanguageCapabilityUtils, {
  LanguageCapability,
} from '@/utils/LanguageCapabilityUtils';
import ProvincePicker from '../province/ProvincePicker.vue';
import CommunityPicker from '../community/CommunityPicker.vue';
import SectorsOfCarePicker from '../sectorsOfCare/SectorsOfCarePicker.vue';

export default Vue.extend({
  components: {
    Autocomplete,
    LanguageDesignationPicker,
    ProvincePicker,
    CommunityPicker,
    SectorsOfCarePicker,
  },

  props: {
    value: {
      type: Object as () => Partial<PointOfService>,
    },
    mode: {
      type: String as () => 'create' | 'edit',
      required: true,
    },
    'error-messages': {
      type: Object as () => { [key: string]: string[] },
      default: () => ({}),
    },

    disableGeocoder: {
      type: Boolean as () => boolean,
    },
  },

  computed: {
    internal: {
      get(): Partial<PointOfService> {
        return (
          this.value || PointOfServiceUtils.pointOfService.generateDefault()
        );
      },
      set(val: Partial<PointOfService>) {
        this.$emit('input', val);
      },
    },

    service_provider_id: {
      get(): number | undefined {
        return this.internal.service_provider?.id;
      },
      set(val: number) {
        if (val) {
          this.internal.service_provider = { id: val };
        } else {
          this.internal.service_provider = undefined;
        }
      },
    },

    language_service_keys() {
      return PointOfServiceUtils.language_service_keys;
    },
  },

  data(): {
    providers?: Provider[];
    addresses?: AutocompleteItem[];
    languageCapabilities?: LanguageCapability[];
    geocodeLoading: boolean;
  } {
    return {
      providers: undefined,
      addresses: undefined,
      languageCapabilities: undefined,
      geocodeLoading: false,
    };
  },

  async mounted() {
    this.providers = await ProviderUtils.api.list();
    this.languageCapabilities =
      await LanguageCapabilityUtils.languageCapability.api.list({
        domain: 'point_of_service',
      });
  },

  methods: {
    pasteCoord(evt: any) {
      const exp = /^(-?\d+(.\d+)?),[ ]*(-?\d+(.\d+)?)$/;
      const text = evt.clipboardData.getData('text');
      if (exp.test(text)) {
        const latLong = text.split(',');
        this.internal.geojson = {
          type: 'Point',
          coordinates: [parseFloat(latLong[1]), parseFloat(latLong[0])],
        };
        evt.preventDefault();
      }
    },

    async handleAutocomplete(value: string) {
      const resp =
        value?.trim() != ''
          ? await SearchUtils.api.address.autocomplete(value)
          : [];
      if (resp?.length != 0) {
        this.addresses = resp.map((address) => ({
          key: address,
          value: {
            address: address,
            latitude: undefined,
            longitude: undefined,
          },
        }));
      } else {
        this.addresses = undefined;
      }
    },

    async handleAddressChange(value: string) {
      if (!this.disableGeocoder) {
        if (value) {
          this.geocodeLoading = true;
          const resp = await SearchUtils.api.address.geocode(value);
          if (!resp.error?.code) {
            this.internal.geojson = {
              type: 'Point',
              coordinates: [resp.longt, resp.latt],
            };
            this.internal.data_quality = {
              geocoder_confidence: resp.standard?.confidence,
            };
          }
          this.geocodeLoading = false;
        } else {
          this.internal.address = undefined;
          this.internal.geojson = {
            type: 'Point',
            coordinates: [0, 0],
          };
        }
      }
    },
  },

  watch: {
    'internal.address': {
      // immediate: true,
      async handler(/*value*/) {
        // this.handleAddressChange(value);
      },
    },
  },
});
