




































import Vue from 'vue';
import { throttle } from 'lodash';
import { AutocompleteItem } from './types';

const autocomplete = throttle(async function (vue: any, search: string) {
  vue.$emit('autocomplete', search);
}, 500);

export default Vue.extend({
  props: {
    value: {
      type: String as () => string,
    },
    items: {
      type: Array as () => AutocompleteItem[],
    },
    label: {
      type: String as () => string,
    },
    prependIcon: {
      type: String as () => string,
    },
  },

  data(): {
    search: string;
    focus: boolean;
    selected: number;
  } {
    return {
      search: this.value || '',
      focus: false,
      selected: -1,
    };
  },

  methods: {
    autocomplete(search: string) {
      this.selected = -1;
      autocomplete(this, search);
    },

    handleFocus() {
      this.focus = true;
      this.selected = -1;
      autocomplete(this, this.search);
    },

    handleBlur() {
      this.$emit('input', this.search);
      setTimeout(() => {
        this.focus = false;
      }, 200); // timeout so events have time to fire
    },

    handleKeypress(event: KeyboardEvent) {
      if (event.code === 'Enter') {
        if (this.selected >= 0) {
          this.handleClick(this.items[this.selected].key, event);
        } else {
          this.handleClick(this.search, event);
        }
        (this.$refs.searchfield as HTMLInputElement).blur();
      } else if (event.code === 'ArrowUp') {
        this.selected = this.selected >= 0 ? this.selected - 1 : this.selected;
      } else if (event.code === 'ArrowDown') {
        this.selected =
          this.selected < this.items?.length - 1
            ? this.selected + 1
            : this.selected;
      }
    },

    handleClick(value: string, event?: KeyboardEvent) {
      this.search = value || '';
      this.$emit('input', value, event);
      this.$emit('change', value);
      (this.$refs.searchfield as HTMLInputElement).blur();
    },
  },

  watch: {
    value(value) {
      this.search = value;
    },
  },
});
