




























import { Province } from '@/utils/ProvinceUtils';
import SectorsOfCareUtils, { SectorOfCare } from '@/utils/SectorsOfCareUtils';
import { isArray } from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: [Object as () => SectorOfCare, Array as () => SectorOfCare[]],
    },
    province: {
      type: Object as () => Province,
    },
    label: {
      type: String as () => string,
    },
    errorMessages: {
      type: Array as () => string[],
    },
    messages: {
      type: [String as () => string, Array as () => string[]],
    },
    loading: {
      type: Boolean as () => boolean,
    },
    disabled: {
      type: Boolean as () => boolean,
    },
    multiple: {
      type: Boolean as () => boolean,
    },
  },

  computed: {
    internal: {
      get(): SectorOfCare | SectorOfCare[] | undefined {
        if (isArray(this.value)) {
          return this.items?.filter((item) =>
            (this.value as SectorOfCare[]).some((val) =>
              this.compare(item, val)
            )
          );
        } else {
          return this.items?.find((item) =>
            this.compare(item, this.value as SectorOfCare)
          );
        }
      },
      set(item: SectorOfCare) {
        this.$emit('input', item);
      },
    },
  },

  data: (): {
    items?: SectorOfCare[];
    state: {
      loading: boolean;
    };
  } => ({
    items: undefined,
    state: {
      loading: false,
    },
  }),

  async mounted() {
    //
  },

  methods: {
    format(value: SectorOfCare) {
      return `${this.$langobj.parse(value.name_short)}`;
    },
    compare(item: SectorOfCare, value: SectorOfCare) {
      if (isArray(item)) {
        return false;
      } else {
        return item.id === value?.id || item.key === value?.key;
      }
    },
  },

  watch: {
    province: {
      immediate: true,
      async handler(value: Province) {
        try {
          this.state.loading = true;
          this.items = await SectorsOfCareUtils.sectorOfCare.api.list(
            value ? { province_key: value.key } : undefined
          );
        } catch (error) {
          console.error(error);
        } finally {
          this.state.loading = false;
        }
      },
    },
  },
});
