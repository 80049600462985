


















import CommunityUtils, { Community } from '@/utils/CommunityUtils';
import { isArray } from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: [Object as () => Community, Array as () => Community[]],
    },
    label: {
      type: String as () => string,
    },
    errorMessages: {
      type: Array as () => string[],
    },
    loading: {
      type: Boolean as () => boolean,
    },
    multiple: {
      type: Boolean as () => boolean,
    },
    clearable: {
      type: Boolean as () => boolean,
    },
  },

  computed: {
    internal: {
      get(): Community | Community[] | undefined {
        if (isArray(this.value)) {
          return this.items?.filter((item) =>
            this.compare(item, this.value as Community)
          );
        } else {
          return this.items?.find((item) =>
            this.compare(item, this.value as Community)
          );
        }
      },
      set(item: Community) {
        this.$emit('input', item);
      },
    },
    itemsSorted(): Community | Community[] | undefined {
      return this.items?.slice().sort((a: Community, b: Community) => {
        const localA = this.$langobj.parse(a.name);
        const localB = this.$langobj.parse(b.name);
        return localA && localB ? localA.localeCompare(localB) : 0;
      });
    },
  },

  data: (): {
    items?: Community[];
  } => ({
    items: undefined,
  }),

  async mounted() {
    try {
      this.items = await CommunityUtils.api.list();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },

  methods: {
    // handleClear() {
    //   this.$emit('input', CommunityUtils.community.generateDefault());
    //   return false;
    // },
    compare(item: Community, value: Community) {
      if (!item) return false;
      return item.id === value?.id;
    },
  },
});
