var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{attrs:{"label":_vm.label,"chips":"","error-messages":_vm.errorMessages,"loading":_vm.state.loading || _vm.loading,"disabled":!(_vm.province && _vm.province.key) || _vm.disabled,"messages":!(_vm.province && _vm.province.key)
      ? _vm.$t('error.required', [_vm.$tc('province.label')])
      : undefined,"multiple":_vm.multiple,"value-comparator":_vm.compare,"items":(_vm.items || []).map(function (item) { return ({
      text: _vm.format(item),
      value: item,
    }); })},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"outlined":"","color":"#216bbf"}},[_vm._v(_vm._s(item.text))])]}}]),model:{value:(_vm.internal),callback:function ($$v) {_vm.internal=$$v},expression:"internal"}})}
var staticRenderFns = []

export { render, staticRenderFns }