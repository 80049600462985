

















import LanguageDesignationUtils, {
  LanguageDesignation,
} from '@/utils/LanguageDesignationUtils';
import { isArray } from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: [
        Object as () => LanguageDesignation,
        Array as () => LanguageDesignation[],
      ],
    },
    label: {
      type: String as () => string,
    },
    errorMessages: {
      type: Array as () => string[],
    },
    loading: {
      type: Boolean as () => boolean,
    },
    multiple: {
      type: Boolean as () => boolean,
    },
  },

  computed: {
    internal: {
      get(): LanguageDesignation | LanguageDesignation[] | undefined {
        if (isArray(this.value)) {
          return this.items?.filter((item) =>
            this.compare(item, this.value as LanguageDesignation)
          );
        } else {
          return this.items?.find((item) =>
            this.compare(item, this.value as LanguageDesignation)
          );
        }
      },
      set(item: LanguageDesignation) {
        this.$emit('input', item);
      },
    },
  },

  data: (): {
    items?: LanguageDesignation[];
  } => ({
    items: undefined,
  }),

  async mounted() {
    try {
      this.items =
        await LanguageDesignationUtils.languageDesignation.api.list();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },

  methods: {
    compare(item: LanguageDesignation, value: LanguageDesignation) {
      return item.id === value?.id || item.key === value?.key;
    },
  },
});
